import React from "react"
import { Grid, Box, Image, Text, Divider, Container } from "theme-ui"
import GyodaImage from '../../../images/store-gyoda.jpg'
import IrumaImage from '../../../images/store-iruma.jpg'

const TimeCell = ({title, text}) => {
    return <Grid
                gap={0}
                columns={[1, '1fr 1fr', 1]}>
                <Box 
                    bg='base' 
                    color='white'
                    pt={1}
                    sx={{
                        textAlign: 'center',
                        fontSize: "20px",
                        fontWeight: 'bolder',
                        height: "44px",
                    }}>
                        {title}
                </Box>
                <Box
                    bg='baseSecond'
                    color='black'
                    pt={2}
                    // pl={3}
                    // pr={3}
                    sx={{
                        textAlign: 'center',
                        fontSize: "14px",
                        height: "44px",
                        whiteSpace: 'nowrap',
                    }}>
                        <Text>{text}</Text>
                </Box>
            </Grid>
}

const TimeSchedule = ({children}) => {
    return <Grid
                gap={1}
                columns={[2, '1fr 1fr', 8]}>
                    {children}
            </Grid>
}

const Branch = ({name, address, access, parking, tell, image }) => {
    return (
    <div>
        <Grid
            gap={2}
            pt={50}
            pb={50}
            columns={[2, '1fr 1.5fr']}
            sx={{
                alignItems: 'top',
            }}
        >
            <Box 
                bg='white'>
                <Text
                    pb={20}
                    sx={{
                        fontSize: 4,
                        fontWeight: 'bold',
                        color: 'base'
                    }}>
                        {name}
                </Text>
                <Text>{address}</Text>
                <Text>{access}</Text>
                <Text>{parking}</Text>
                <Text
                    pt={10}
                    sx={{}}>
                        {tell}
                </Text>
            </Box>
            <Box sx={{
                textAlign: 'center',
                alignItems: 'center',
            }}>
                <Image 
                    src={image}
                    sx={{
                        width: '100%',
                        maxWidth: '600px',
                    }}
                />
            </Box>
        </Grid>
    </div>
    )
}

const IrumaBranch = ({name, address, access, parking, tell, image }) => {
    return (
    <div>
        <Grid
            gap={2}
            pt={50}
            pb={50}
            columns={[2, '1fr 1.5fr']}
            sx={{
                alignItems: 'top',
            }}
        >
            <Box
                bg='white'>
                <Text
                    pb={20}
                    sx={{
                        fontSize: 4,
                        fontWeight: 'bold',
                        color: 'base'
                    }}>
                        {name}
                </Text>
                <Text>{address}</Text>
                <Text>{access}</Text>
                <Text>{parking}</Text>
                <Text>NOV化粧品の取り扱いあり</Text>
                <Text
                    pt={10}
                    sx={{
                        fontSize: 2,
                        fontWeight: 'bold',
                        color: 'base'
                    }}>
                        アルバイト・パート募集中
                </Text>
                <Text>週1〜/午後勤務可能な方/詳細はお電話にて</Text>
                <Text>事務　1000円〜/時</Text>
                <Text>薬剤師　2000円〜/時</Text>
                <Text
                    pt={10}
                    sx={{}}>
                        {tell}
                </Text>
            </Box>
            <Box sx={{
                textAlign: 'center',
                alignItems: 'center',
            }}>
                <Image
                    src={image}
                    sx={{
                        width: '100%',
                        maxWidth: '600px',
                    }}
                />
            </Box>
        </Grid>
    </div>
    )
}

const Projects = () => {
    return (
        <Container
            id="branch"
            pt={50}
            pb={50}
        >
            <Text
                pb={0}
                sx={{
                    fontSize: 6,
                    fontWeight: 'bold',
                    color: 'base'
                }}>
                店舗情報
            </Text>
            <Branch 
                name='行田店'
                address='埼玉県行田市長野１２６３−７'
                access='秩父鉄道秩父本線東行田駅から徒歩7分'
                parking='無料駐車場あり'
                tell='Tell: 048-556-1402'
                image={GyodaImage}
            />
            <TimeSchedule >
                <TimeCell title='月' text='8:30 - 19:00'/>
                <TimeCell title='火' text='8:30 - 17:00'/>
                <TimeCell title='水' text='8:30 - 17:00'/>
                <TimeCell title='木' text='8:30 - 19:00'/>
                <TimeCell title='金' text='8:30 - 19:00'/>
                <TimeCell title='土' text='8:30 - 18:30'/>
                <TimeCell title='日' text='8:30 - 18:30'/>
                <TimeCell title='祝' text='8:30 - 18:30'/>
            </TimeSchedule>
            <Container pb={50} />
            <Divider />
            <IrumaBranch
                name='入間店'
                address='埼玉県入間市宮寺３０６１番地１'
                access='西武池袋線狭山ヶ丘駅から車で12分'
                parking='無料駐車場あり'
                tell='Tell: 04-2934-6020'
                image={IrumaImage}
            />
            <TimeSchedule >
                <TimeCell title='月' text='9:00 - 17:00'/>
                <TimeCell title='火' text='9:00 - 18:00'/>
                <TimeCell title='水' text='9:00 - 18:00'/>
                <TimeCell title='木' text='9:00 - 18:00'/>
                <TimeCell title='金' text='9:00 - 18:00'/>
                <TimeCell title='土' text='9:00 - 17:00'/>
                <TimeCell title='日' text='9:00 - 17:00'/>
                <TimeCell title='祝' text='9:00 - 17:00'/>
            </TimeSchedule>
        </Container>
    )
}

export default Projects;