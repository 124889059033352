import React from "react"
import { Grid, Box, Image, Text } from "theme-ui";
import MedicineImage from '../../../images/medicine.svg'

const About = () => (
    <Grid
        pt={100}
        pb={100}
        pl={0}
        pr={4}
        columns={[ 1, 1, 1, 1, 2 ]}
        id="about"
    >
        <Box>
            <Text
                pb={10}
                sx={{
                    fontSize: 6,
                    fontWeight: 'bold',
                    color: 'base'
                }}>
                土日・祝日も営業
            </Text>
            <Text
                sx={{
                    fontSize: 1,
                }}>
                    トーア薬局は埼玉県行田市と入間市にある薬局です。安心と信頼をお届けできる薬局づくりを目指しています。薬の飲み方や量、体調のことなど、その他どんな些細なことでもご相談ください。
            </Text>
        </Box>
        
        <Box sx={{
            textAlign: 'center'
        }}>
            <Image src={MedicineImage}
                   sx={{
                       maxWidth: '30%',
                       minWidth: '300px',
                       transform: 'rotate(5deg)',
                       pt: '40px'
                   }}
            />
        </Box>
    </Grid>
)

export default About;