import React from "react";
import ContactMDX from "../sections/contact"
import {Box, Grid, Image, Text } from "theme-ui";
import MailBox from '../../../images/mailbox.svg'

const Contact = () => (
    <Grid
        pt={100}
        pb={100}
        pl={4}
        pr={4}
        columns={[ 1, 2 ]}
        id="contact"
    >
        <Box sx={{
            textAlign: 'center'
        }}>
            <Image src={MailBox}
                   sx={{
                       maxWidth: '50%',
                       minWidth: '200px',
                       transform: 'rotate(-5deg)'
                   }}
            />
        </Box>
        <Box>
        <Text
            pb={0}
            sx={{
                fontSize: 6,
                fontWeight: 'bold',
                color: 'base'
            }}>
                お問い合わせ
        </Text>
        <ContactMDX/>
        </Box>
    </Grid>
)

export default Contact;