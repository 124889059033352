import React from "react"
import { Container, Image } from 'theme-ui'
import bannerIcon from '../../../images/banner.jpg'
import Header from "../components/header"

const Banner = () => (
    <Container
        pt={0}
        pb={0}
    >
        <Header />
        <Image src={bannerIcon}
            sx={{
                width: '100%',
            }}
        />
    </Container>
)

export default Banner;