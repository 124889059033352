import React from "react"
import Banner from "@openarchitex/gatsby-theme-minimal-portfolio/src/components/banner"
import Layout from "@openarchitex/gatsby-theme-minimal-portfolio/src/components/layout"
import About from "@openarchitex/gatsby-theme-minimal-portfolio/src/components/about"
import Store from "@openarchitex/gatsby-theme-minimal-portfolio/src/components/projects"
import Contact from "@openarchitex/gatsby-theme-minimal-portfolio/src/components/contact"
import Footer from "../@openarchitex/gatsby-theme-minimal-portfolio/components/footer.js"
import { Divider } from "theme-ui";
import SEO from "../components/seo"

const TopPage = () => {
  return (
    <div>
      <SEO title="ホーム" />
      <Banner />
      <Layout>
        <About/>
        <Divider />
        <Store/>
        <Divider />
        <Contact/>
      </Layout>
      <Footer/>
    </div>
  ) 
}

export default TopPage
